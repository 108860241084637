<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "ADD" | translate }} {{ "NEW" | translate }} {{ "GCP" | translate }} {{ "ACCOUNT" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT" | translate }} {{ "GCP" | translate }} {{ "ACCOUNT" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <div class="form-group">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="gcpAccount.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ gcpAccountNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="file" [ngClass]="{ 'is-invalid': form.submitted && fileContent.errors }"
                            >{{ "GCP_SERVICE_ACC_KEY_FILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="file"
                            id="fileUpload"
                            name="fileUpload"
                            [ngClass]="{ 'd-none': authJson, 'is-invalid': form.submitted && fileContent.errors }"
                            class="w-100"
                            (change)="onFileChange($event)"
                        />
                        <textarea
                            class="form-control"
                            rows="5"
                            id="fileContent"
                            name="fileContent"
                            required
                            #fileContent="ngModel"
                            [(ngModel)]="authJson"
                            [ngClass]="{ 'd-none': !authJson, 'is-invalid': form.submitted && fileContent.errors }"
                        ></textarea>
                        <div *ngIf="fileContent.invalid" class="invalid-feedback">
                            <div *ngIf="fileContent.errors.required">{{ "GCP_SERVICE_ACC_KEY_FILE" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                        </div>
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="jsonError" class="mb-0">
                        <strong>{{ "ERROR" | translate }}</strong
                        >:&nbsp;{{ "JSON_ERROR" | translate }}
                    </ngb-alert>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
