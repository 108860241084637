import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { NgbSortableHeader } from "../../../../directives/sortable.directive";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../../account-management/users/users.service";
import { GcpAccount, KeyMap } from "../../../../models/shared";

import { GcpAccountsService } from "../gcp-accounts.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { Router } from "@angular/router";

import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-gcp-account-list",
    templateUrl: "./gcp-account-list.component.html"
})
export class GcpAccountListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    gcpAccounts: GcpAccount[];

    sortedByText = "NONE";
    isAdmin: boolean;
    urls = Constants.urls;

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;
    @ViewChild("listTop", { static: true }) listTop: ElementRef;
    @ViewChild("listBottom", { static: true }) listBottom: ElementRef;

    private gcpAccountsSubscription: Subscription;

    private gcpAccountsBS$ = new BehaviorSubject<GcpAccount[]>([]);
    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GcpAccount>>(
                (row: KeyMap<GcpAccount>): string => row?.name,
                (row: KeyMap<GcpAccount>): string => row?.name,
                (): boolean => true
            ),
            sortBy: (row: KeyMap<GcpAccount>): string => row?.name,
            textValue: (row: KeyMap<GcpAccount>): string => row?.name
        },
        {
            header: this.translate.instant("Service Account ID"),
            columnDef: "service account id",
            width: 300,
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GcpAccount>>(
                (row: KeyMap<GcpAccount>): string => row?.client_email,
                (row: KeyMap<GcpAccount>): string => row?.client_email,
                (): boolean => true
            ),
            sortBy: (row: KeyMap<GcpAccount>): string => row?.client_email,
            textValue: (row: KeyMap<GcpAccount>): string => row?.client_email
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            align: "right",
            sticky: 3,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<GcpAccount, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row)
            })
        }
    ];

    constructor(
        private router: Router,
        private ak: GcpAccountsService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("GCP_ACCOUNTS"));
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.gcpAccountsSubscription = this.ak.gcpAccounts.subscribe(gcpAccounts => {
            this.gcpAccounts = gcpAccounts;
            if (this.gcpAccounts) {
                this.prepTableData();
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.gcpAccountsSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const gcpAccounts = this.ak.refreshGcpAccounts().toPromise();
        await Promise.all([gcpAccounts]);

        this.refreshing = false;
    }

    edit(gcpAccount: GcpAccount): void {
        this.router.navigate([Constants.urls.configuration.gcp, gcpAccount.id, "edit"]);
    }

    async delete(gcpAccount: GcpAccount) {
        await this.modalService.confirm(
            "DELETE",
            "GCP_ACCOUNT",
            async () => {
                const id = gcpAccount.id;
                const result = await this.ak.deleteGcpAccount(gcpAccount);
                if (result) {
                    this.mixpanelService.sendEvent("delete gcp account", { id });
                } else return false;
            },
            gcpAccount.name
        );
    }

    get gcpAccounts$() {
        return this.gcpAccountsBS$.asObservable();
    }

    private prepTableData() {
        if (this.gcpAccounts) {
            this.gcpAccountsBS$.next([...this.gcpAccounts]);
        }
    }
}
