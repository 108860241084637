import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { GcpAccountsComponent } from "./gcp-accounts.component";
import { GcpAccountListComponent } from "./gcp-account-list/gcp-account-list.component";
import { GcpAccountFormComponent } from "./gcp-account-form/gcp-account-form.component";

// Resolver
import { GcpAccountListResolverService } from "./gcp-account-list/gcp-account-list-resolver.service";

const gcpAccountRoutes: Routes = [
    {
        path: Constants.urls.configuration.gcp + "/new",
        component: GcpAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.gcp + "/:id/:action",
        component: GcpAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.gcp,
        component: GcpAccountsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GCP_ACCOUNTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/GgBEJg"
                }
            ]
        },
        children: [
            {
                path: "",
                component: GcpAccountListComponent,
                resolve: {
                    gcpAccount: GcpAccountListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(gcpAccountRoutes)],
    exports: [RouterModule]
})
export class GcpAccountsRouting {}
