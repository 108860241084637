import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { GcpAccountsComponent } from "./gcp-accounts.component";
import { GcpAccountFormComponent } from "./gcp-account-form/gcp-account-form.component";
import { GcpAccountListComponent } from "./gcp-account-list/gcp-account-list.component";

// Routes
import { GcpAccountsRouting } from "./gcp-accounts.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, GcpAccountsRouting],
    declarations: [GcpAccountsComponent, GcpAccountFormComponent, GcpAccountListComponent]
})
export class GcpAccountsModule {}
