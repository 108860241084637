<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'GCP_ACCOUNTS' | translate }}">{{ "GCP_ACCOUNTS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem id="gcp-accounts-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'ACCOUNTS' | translate }}">{{ "ACCOUNTS" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem id="gcp-accounts-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'DOCUMENTATION' | translate }}">{{ "DOCUMENTATION" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <h3 translate>GCP_DOCS.TITLE</h3>
                                <h4 translate>GCP_DOCS.PART_ONE.TITLE</h4>
                                <ol>
                                    <li>
                                        {{ "GCP_DOCS.PART_ONE.ONE" | translate }}
                                        <a href="https://console.cloud.google.com/iam-admin/serviceaccounts" target="_blank"
                                            >https://console.cloud.google.com/iam-admin/serviceaccounts</a
                                        >
                                    </li>
                                    <li translate>GCP_DOCS.PART_ONE.TWO</li>
                                    <li>
                                        {{ "GCP_DOCS.NOTES.COMPUTE_ENGINE_API" | translate }}
                                        <br />
                                        <a href="https://console.developers.google.com/apis/api/compute.googleapis.com/overview" target="_blank"
                                            >https://console.developers.google.com/apis/api/compute.googleapis.com/overview
                                        </a>
                                    </li>
                                    <li translate>GCP_DOCS.PART_ONE.THREE</li>
                                    <li translate>GCP_DOCS.PART_ONE.FOUR</li>
                                    <li>
                                        {{ "GCP_DOCS.PART_ONE.FIVE" | translate }}<br />
                                        <strong>Compute Admin</strong> {{ "AND" | translate }} <strong>Service Account User</strong>
                                        {{ "GCP_DOCS.PART_ONE.FIVE_A_POST" | translate }}<br />
                                        <strong>Storage Admin</strong> {{ "GCP_DOCS.PART_ONE.FIVE_B_POST" | translate }}
                                    </li>
                                    <li translate>GCP_DOCS.PART_ONE.SIX</li>
                                    <li translate>GCP_DOCS.PART_ONE.SEVEN</li>
                                </ol>
                                <h4 translate>GCP_DOCS.PART_TWO.TITLE</h4>
                                <ol>
                                    <li translate>GCP_DOCS.PART_TWO.ONE</li>
                                    <li translate>GCP_DOCS.PART_TWO.TWO</li>
                                    <li translate>GCP_DOCS.PART_TWO.THREE</li>
                                </ol>
                            </div>
                            <div class="col mt-3">
                                <h3 translate>NOTES</h3>
                                <ul>
                                    <li>
                                        {{ "GCP_DOCS.NOTES.ROLES" | translate }}
                                        <br />
                                        <a href="https://console.developers.google.com/access/iam" target="_blank"
                                            >https://console.developers.google.com/access/iam</a
                                        >
                                    </li>
                                    <li>
                                        {{ "GCP_DOCS.NOTES.STORAGE" | translate }}
                                        <br />
                                        <a href="https://console.cloud.google.com/storage/browser" target="_blank"
                                            >https://console.cloud.google.com/storage/browser</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
