<!-- List Panel -->
<div id="list-panel" class="list-panel" #listPanel>
    <div class="list-container overflow-y-hidden">
        <!-- Table -->
        <app-table-list
            [data]="gcpAccounts$ | async"
            [tableName]="'gcp_accounts'"
            [displayTableName]="'GCP_ACCOUNTS' | translate"
            [(tableSchema)]="tableColumnsSchema"
            (selectedRows)="(false)"
            [showReport]="false"
            [isColumnSelectionNeeded]="false"
            [showSelectionCheckbox]="false"
            [selectable]="false"
            [hoverable]="false"
        >
            <div class="form-group mb-0">
                <button
                    type="button"
                    class="btn btn-outline-primary me-2"
                    routerLink="/{{ urls.configuration.gcp }}/new"
                    title="{{ 'ADD' | translate }} {{ 'KEY' | translate }}"
                >
                    <fa-icon icon="plus" size="sm"></fa-icon>
                    <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }} {{ "ACCOUNT" | translate }}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                    <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                    <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                </button>
            </div>
        </app-table-list>
    </div>
</div>
