import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { GcpAccountsService } from "../gcp-accounts.service";
import { GcpAccount } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class GcpAccountListResolverService implements Resolve<GcpAccount[]> {
    constructor(private tps: GcpAccountsService) {}

    resolve(): Observable<GcpAccount[]> | Observable<never> {
        return new Observable((observe: Subscriber<GcpAccount[]>) => {
            this.tps.refreshGcpAccounts().subscribe((gcpAccounts: GcpAccount[]) => {
                observe.next(gcpAccounts);
                observe.complete();
            });
        });
    }
}
